import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion'


let dataSheetsRFID = [
  {
    "title": "Orbit | Smart | RFID | Deadbolt | 12MM | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5bTuS2ZfLeWCxfHOm4qARy/ab3fb9787230c1e5db77d6d998bf581f/DS-DL-D6ON-D-01-DEN.pdf",
      "fileName": "DS-DL-D6ON-D-01-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Orbit | Smart | RFID | Deadbolt | 15-19MM | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3l71bjmiBJyghlMc4N7FK1/fbb6a848569c47cd6a1365b45f837978/DS-DL-D6ON-D-02-DEN.pdf",
      "fileName": "DS-DL-D6ON-D-02-DEN.pdf",
      "contentType": "application/pdf"
    },
  },
  {
    "title": "Orbit | Smart | RFID | Deadlatch | 12MM | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7xiIglfPwRcnlhtSxRg7TG/5e508b6037810a653e1f090208f15f58/DS-DL-D6ON-P-01-DEN.pdf",
      "fileName": "DS-DL-D6ON-P-01-DEN.pdf",
      "contentType": "application/pdf"
    },
  },
  {
    "title": "Orbit | Smart | RFID | Deadlatch | 15-19MM | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/1Y6nshJajpD9J2t5rOh50S/cf2821704b5f22f6ec723e3844706680/DS-DL-D6ON-P-02-DEN.pdf",
      "fileName": "DS-DL-D6ON-P-02-DEN.pdf",
      "contentType": "application/pdf"
    },
  }
];
let installGuidesRFID = [
  {
    "title": "Orbit | Smart ",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/7lFQEaExfr4DgofKWivePZ/e66a20372070d3ffc67a6d878ca6a9ed/IG-D6ON-KEY-BYPASS-DEN.pdf",
      "fileName": "IG-D6ON-KEY-BYPASS-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Orbit <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'../../../../images/locks/DL22-net-orbit-600x497.png'}
                  loading={'lazy'}
                  width={1200}
                  height={889}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Smart Lock"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsRFID.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Tab;